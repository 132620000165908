// @ts-nocheck
import React from 'react'
import styled, {css} from 'styled-components'
import {FieldProps} from 'formik'
import {Form} from 'react-bootstrap'

type Props = {
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  checkbox?: boolean
  textarea?: boolean
  type?: string
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`

const Input = styled(Form.Control)<{error: boolean; istextarea?: boolean}>`
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 1.5rem;
  border-radius: 5px;
  /* cursor: pointer; */

  &:read-only {
    background-color: #f6f6f6;
  }

  ${({istextarea}) =>
    istextarea &&
    css`
      resize: none;
      min-height: 120px;
    `};

  ${({error}) =>
    error &&
    css`
      border-color: red;
    `};
`

const InputLabel = styled(Form.Label)``

const Checkbox = styled(Form.Check)<{error: boolean}>`
  margin-right: 1rem;
`

const ErrorMessage = styled(Form.Label)`
  text-align: left;
  color: red;
`

const FormInput: React.FC<Props & FieldProps> = ({
  field: {name, onChange, onBlur, value},
  form: {touched, errors},
  label,
  required,
  placeholder,
  disabled,
  readonly,
  checkbox,
  textarea,
  type,
}) => {
  const errorText = errors[name] && touched[name] && errors[name]

  return checkbox ? (
    <>
      {!!errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      <Checkbox
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        readOnly={!!readonly}
        required={required}
        $error={!!errorText}
      />
    </>
  ) : (
    <InputWrapper>
      {!!errorText ? <ErrorMessage>{errorText}</ErrorMessage> : <InputLabel>{label}</InputLabel>}
      <Input
        as={textarea ? 'textarea' : ''}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        readOnly={!!readonly}
        required={required}
        $error={!!errorText}
        $istextarea={!!textarea}
      />
    </InputWrapper>
  )
}

export default FormInput

import React from 'react'
import styled from 'styled-components'
import {Modal} from 'react-bootstrap'
import EditCustomerDataForm from '../forms/EditCustomerDataForm'

type Props = {
  visible: boolean
  close: () => void
}

const ModalBody = styled(Modal.Body)`
  max-height: 90%;
`

const StyledModal = styled(Modal)`
  margin-top: 50px;

  @media (min-width: 768px) {
    & > div {
      max-width: 500px;
    }
  }
`

const NewEventModal: React.FC<Props> = ({visible, close}) => {
  return (
    <StyledModal show={visible} onHide={close}>
      <ModalBody>
        <EditCustomerDataForm />
      </ModalBody>
    </StyledModal>
  )
}

export default NewEventModal

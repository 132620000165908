import React from 'react'
import styled from 'styled-components'

import {Modal, Button} from 'react-bootstrap'

type Props = {
  visible: boolean
  close: () => void
}

const ModalWrapper = styled(Modal)`
  z-index: 9999;
  background: #00000057;

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div`
  position: relative;
  padding-bottom: 1.75rem;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -50px;
    width: 120%;
    height: 1px;
    background-color: #e4e6ef;
  }
`

const HeaderText = styled.p`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0;
  color: red;
`

const TextWrapper = styled.div`
  & > p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
`

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1.75rem;

  & > button {
    :first-child {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -50px;
    width: 120%;
    height: 1px;
    background-color: #e4e6ef;
  }
`

const EventsErrorModal: React.FC<Props> = ({visible, close}) => {
  return (
    <ModalWrapper show={visible}>
      <Body>
        <Header>
          <HeaderText>Błąd!</HeaderText>
        </Header>
        <TextWrapper>
          <p>Wystąpił błąd poczas ładowania wydarzeń.</p>
        </TextWrapper>
        <ModalFooter>
          <Button variant='primary' onClick={close}>
            OK
          </Button>
        </ModalFooter>
      </Body>
    </ModalWrapper>
  )
}

export default EventsErrorModal

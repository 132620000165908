//@ts-nocheck

import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Check, XLg, CardImage, Trash, HandIndex} from 'react-bootstrap-icons'
import styled, {css} from 'styled-components'
import {AttachmentType} from '../../../../types/types'

type Props = {
  index: number
  highlighted: boolean
  imgURL: string
  imgBase64: string
  isNewImg: boolean
  // editedImgSrc?: string
  disabled: boolean
  // selected: boolean
  changeCurrentImg: (attachment: AttachmentType) => void
  deleteAttachment: (index: number) => void
  // selectAttachment: (index: number, selected: boolean) => void
}

const ButtonDeleteAttachment = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
  background-color: #ff0000;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: #ff5555;
  }
`

const Thumbnail = styled.div<{highlighted: boolean; disabled?: boolean}>`
  position: relative;
  width: 65px;
  height: 65px;

  box-shadow: 1px 1px 7px 1px #786e6e5c;
  border-radius: 5px;

  cursor: pointer;

  /* &:hover {
    border: 1px solid #009ef7;
  } */

  :not(:last-child) {
    margin-right: 1.15rem;
  }

  ${({highlighted}) =>
    highlighted &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid #009ef7;
        border-radius: 5px;
      }
    `};

  ${({disabled}) =>
    disabled &&
    css`
      pointer-events: none;
      & > ${ButtonDeleteAttachment} {
        display: none;
      }
    `};
`

const ThumbnailImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

const CheckIconWrapper = styled.div`
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #ffffff; */
`

// const ThumbnailSelectedOverlay = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: rgba(0, 0, 0, 0.35);
// `

const ActionPopup = styled.ul<{visible: boolean}>`
  position: absolute;
  top: -30px;
  left: 50%;

  transform: translate(-50%, -100%);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1.2rem 1.5rem;
  background-color: #fff;
  box-shadow: 0 3px 22px 6px rgb(0 0 0 / 15%);
  border-radius: 5px;
  visibility: hidden;

  ${({visible}) =>
    visible &&
    css`
      visibility: visible;
    `}

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;

    border-top: 12px solid #fff;
  }
`

const ActionListItem = styled.li<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  border-radius: 15px;

  &:hover {
    background-color: #f8f9fb;
    color: #5097ca;
  }

  & > svg {
    margin-right: 1rem;
  }

  ${({disabled}) =>
    disabled &&
    css`
      color: #bbbbbb;
      pointer-events: none;
      cursor: default;
    `}
`

const ConfirmationModal = styled(Modal)`
  z-index: 9999;
  background: #00000057;

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ModalHeader = styled.div`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -50px;
    width: 120%;
    height: 1px;
    background-color: #e4e6ef;
  }

  & > div {
    font-size: 20px;
    font-weight: bold;
  }
`

const ModalTextWrapper = styled.div`
  & > p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
`

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1.75rem;

  & > button {
    :first-child {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -50px;
    width: 120%;
    height: 1px;
    background-color: #e4e6ef;
  }
`

const Attachment: React.FC<Props> = ({
  index,
  highlighted,
  imgURL,
  imgBase64,
  isNewImg,
  // selected,
  disabled,
  changeCurrentImg,
  deleteAttachment,
  // selectAttachment,
  // editedImgSrc,
}) => {
  const [actionPopupVisible, setActionPopupVisible] = useState(false)
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)

  const getSrc = () => {
    if (imgURL) {
      return imgURL
    }
    return imgBase64
  }

  return (
    <>
      <Thumbnail
        disabled={disabled}
        onClick={() => setActionPopupVisible((prevVal) => !prevVal)}
        highlighted={highlighted}
      >
        <ThumbnailImg src={getSrc()} alt='' />

        <ButtonDeleteAttachment
          onClick={(event) => {
            event.stopPropagation()
            setConfirmationModalVisible(true)
          }}
        >
          <XLg color='#ffffff' />
        </ButtonDeleteAttachment>
        {isNewImg && (
          <CheckIconWrapper>
            <Check color='#00a000' size={20} />
          </CheckIconWrapper>
        )}

        <ActionPopup visible={actionPopupVisible}>
          <ActionListItem
            disabled={highlighted}
            onClick={() => {
              changeCurrentImg({
                imgURL,
                imgBase64,
                isNewImg,
                index,
                // selected,
              })
            }}
          >
            <CardImage color='#bbbbbb' size={18} />
            Podgląd
          </ActionListItem>
          <ActionListItem
            onClick={() => {
              setConfirmationModalVisible(true)
              // changeCurrentImg({
              //   imgBase64,
              //   isNewImg,
              //   index,
              //   selected,
              // })
            }}
          >
            <Trash color='#bbbbbb' size={18} />
            Usuń
          </ActionListItem>
          {/* <ActionListItem
          onClick={() => {
            selectAttachment(index, !selected)
          }}
          >
          <HandIndex color='#bbbbbb' size={18} />
          {selected ? 'Odznacz' : 'Wybierz'}
        </ActionListItem> */}
        </ActionPopup>

        {/* {selected && (
          <ThumbnailSelectedOverlay>
            <Check color='#00aa00' size={30} />
          </ThumbnailSelectedOverlay>
        )} */}
      </Thumbnail>
      {confirmationModalVisible && (
        <ConfirmationModal show={confirmationModalVisible}>
          <ModalBody>
            <ModalHeader>
              <div
                dangerouslySetInnerHTML={{
                  __html: 'Usuwanie załącznika',
                }}
              />
            </ModalHeader>
            <ModalTextWrapper>
              <p>Czy na pewno chcesz usunąć załącznik?</p>
            </ModalTextWrapper>
            <ModalFooter>
              <Button variant='secondary' onClick={() => setConfirmationModalVisible(false)}>
                Anuluj
              </Button>
              <Button variant='danger' onClick={() => deleteAttachment(index)}>
                Usuń
              </Button>
            </ModalFooter>
          </ModalBody>
        </ConfirmationModal>
      )}
    </>
  )
}

export default Attachment

import React from 'react'
import styled from 'styled-components'

type Props = {
  color: string
  min: string
  max: string
  onChange: (size: string) => void
}

const RangeInput = styled.input<{color: string}>`
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;

  width: 12rem;

  &::-webkit-slider-runnable-track {
    background: #000000;
    height: 2px;
    border-radius: 5px;
  }

  &::-moz-range-track {
    background: #000000;
    height: 2px;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    margin-top: -7px;
    background-color: ${({color}) => color};
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    margin-top: -7px;
    border: none;
    border-radius: 50%;
  }
`

const CustomBrushSizeSlider: React.FC<Props> = ({color, min, max, onChange}) => {
  return (
    <RangeInput
      type='range'
      color={color}
      min={min}
      max={max}
      onChange={(e) => onChange(e.target.value)}
      defaultValue={min}
    />
  )
}

export default CustomBrushSizeSlider

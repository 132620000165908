//@ts-nocheck
import React, {useEffect, useState, useRef, useCallback} from 'react'
import styled, {css} from 'styled-components'
import {Button, Modal, Row, Col} from 'react-bootstrap'

import {ArrowLeft, PencilFill} from 'react-bootstrap-icons'

import EditCustomerDataModal from '../modals/EditCustomerDataModal'
import InfoModal from './InfoModal'
import ImageEditor from '../../../partials/layout/image-editor/ImageEditor'
import EventInfo from '../../../partials/layout/event-info/EventInfo'
import Attachments from '../../../partials/layout/attachments/Attachments'

import {convertBase64ToBlobs} from '../../../../utils/convertBase64ToBlob'
import {saveServiceImages, saveUploadedImages} from '../../../../api_wentklima'

import {AttachmentType, Event} from '../../../../types/types'

const emptyAttachmentObj: AttachmentType = {
  imgURL: '',
  imgBase64: '',
  imgFile: null,
  isNewImg: false,
  // editedImgSrc: '',
  // selected: false,
  index: null,
}

type Props = {
  visible: boolean
  close: (modalType: string) => void
  refetch: () => void
  event: Event | null
}

const StyledModal = styled(Modal)<{overlay: boolean}>`
  & > div {
    ${({overlay}) =>
      overlay &&
      css`
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.15);
        }
      `};
  }

  @media (min-width: 768px) {
    & > div {
      max-width: 650px;
    }
    @media (min-width: 1200px) {
      & > div {
        max-width: 820px;
      }
    }
  }
`

const ModalBody = styled(Modal.Body)`
  max-height: 90%;
  border: none;
`

const ModalHeader = styled(Modal.Header)`
  display: block;
  border: none;
`

const ModalHeaderIcons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > svg:hover {
    cursor: pointer;
    fill: #000000;
  }
`

const EditIconWrapper = styled.div<{absolute?: boolean}>`
  ${({absolute}) =>
    absolute &&
    css`
      position: absolute;
    `};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #52bdf6;
  cursor: pointer;

  :hover {
    background-color: #41acf5;
  }
`

const EventImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1.35rem;
`

const MainWrapper = styled.div<{withBorder: boolean}>`
  position: relative;
  width: 100%;
  border-radius: 5px;

  & > ${EditIconWrapper} {
    bottom: 15px;
    right: 15px;
  }

  ${({withBorder}) =>
    withBorder &&
    css`
      border: 2px solid #009ef7;
    `}
`

const EventButtons = styled(Row)`
  button {
    position: relative;
    width: 145px;
    height: 43px;
  }
`

const CurrentImg = styled.img`
  width: 100%;
  border-radius: 5px;
`

const EventModal: React.FC<Props> = ({visible, close, refetch, event}) => {
  const [editFormVisible, setEditFormVisible] = useState(false)
  const [imageEditorVisible, setImageEditorVisible] = useState(false)
  const [currentImg, setCurrentImg] = useState<AttachmentType>(emptyAttachmentObj)
  const [uploadedImages, setUploadedImages] = useState<File[] | []>([])
  const [attachmentsImages, setAttachmentImages] = useState<AttachmentType[]>([])
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [savingSuccess, setSavingSuccess] = useState(false)
  const [savingError, setSavingError] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isAfterSave, setIsAfterSave] = useState(false)

  const initialAttachmentsRef = useRef()

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newUploadedImages = [...uploadedImages]
      const newCurrentImage = {...emptyAttachmentObj}

      newUploadedImages.push(event.target.files[0])
      newCurrentImage.imgFile = event.target.files[0]
      newCurrentImage.fileName = event.target.files[0].name
      newCurrentImage.isNewImg = true

      setImageEditorVisible(true)
      setUploadedImages(newUploadedImages)
      setCurrentImg(newCurrentImage)
    }
  }

  const handleAddImageToAttachmentsAndForm = (imgBase64: string, fileName: string) => {
    const index = attachmentsImages.length

    let newAttachmentImages = [...attachmentsImages]
    let newImageObj: AttachmentType = {
      imgURL: '',
      imgBase64: '',
      imgFile: null,
      fileName,
      isNewImg: true,
      // editedImgSrc: '',
      // selected: false,
      index,
    }

    newImageObj.imgBase64 = imgBase64
    newAttachmentImages.push(newImageObj)

    // else {
    //   newImageObj.img = currentImg.imgSrc
    //   newImageObj.editedImgSrc = image
    //   newAttachmentImages[index] = newImageObj
    // }

    setAttachmentImages(newAttachmentImages)
    setCurrentImg(newImageObj)
    setImageEditorVisible(false)
  }

  const resetAll = () => {
    // set everything to inital values
    if (event && event.images.length > 0) {
      setCurrentImg({...emptyAttachmentObj, imgURL: event.images[0], index: 0})
    }
    if (!isAfterSave) {
      setAttachmentImages(initialAttachmentsRef.current)
    }
    setUploadedImages([])
    setImageEditorVisible(false)
  }

  // const handleAttachmentSelectToggle = (index: number, selected: boolean) => {
  //   const updatedArray = [...attachmentsImages]
  //   updatedArray[index] = {
  //     ...updatedArray[index],
  //     selected,
  //   }
  //   setAttachmentImages(updatedArray)
  // }

  const handleAttachmentDelete = (index: number) => {
    const updatedArray = [...attachmentsImages]
    updatedArray.splice(index, 1)
    // reassing index
    updatedArray.map((attachment, index) => (attachment.index = index))

    setAttachmentImages(updatedArray)

    if (index === 0) {
      setCurrentImg(emptyAttachmentObj)
    } else {
      setCurrentImg(updatedArray[index - 1])
    }
  }

  const isAttachmentsArrayEqualWithInitial = useCallback(() => {
    let areArrayObjectsEqual = true
    if (initialAttachmentsRef.current.length === attachmentsImages.length) {
      initialAttachmentsRef.current.forEach((attachment: AttachmentType, index) => {
        if (
          attachment.imgBase64 === attachmentsImages[index].imgBase64 &&
          attachment.isNewImg === attachmentsImages[index].isNewImg &&
          attachment.imgFile === attachmentsImages[index].imgFile &&
          attachment.fileName === attachmentsImages[index].fileName &&
          attachment.index === attachmentsImages[index].index
          // && attachment.editedImgSrc === attachmentsImages[index].editedImgSrc
          // && attachment.selected === attachmentsImages[index].selected
        ) {
          return
        }
        areArrayObjectsEqual = false
        return
      })
    } else {
      areArrayObjectsEqual = false
    }

    setSaveButtonDisabled(areArrayObjectsEqual)
  }, [attachmentsImages])

  const handleSaveChanges = async () => {
    setLoading(true)

    const uploadedFilesToSave = await convertBase64ToBlobs(
      attachmentsImages
        .filter((attachment) => attachment.isNewImg)
        .map((attachment) => attachment.imgBase64)
    )

    const imagesServiceResponse = await saveUploadedImages(uploadedFilesToSave)

    if (imagesServiceResponse.data?.code !== 200 || !imagesServiceResponse) {
      const errorMessage = 'Wystąpił błąd podczas zapisywania zdjęć.'

      setSavingError(true)
      setErrorMessage(errorMessage)
      setLoading(false)

      throw new Error(errorMessage)
    }

    const attachmentsImagesUrls: string[] = attachmentsImages
      .filter((attachment) => !attachment.isNewImg)
      .map((attachment) => attachment.imgURL)

    const uploadedImagesUrls: string[] = imagesServiceResponse?.data?.uploads?.map(
      (upload) => `https://pliki-klimatyzacjawentklima.pl${upload.fileName}`
    )

    const updatedEventImagesUrls: string[] = [...attachmentsImagesUrls, ...uploadedImagesUrls]

    const wentklimaAPIResponse = await saveServiceImages(updatedEventImagesUrls, event.id)

    if (imagesServiceResponse.data?.error || !wentklimaAPIResponse.data?.result) {
      const errorMessage = 'Wystąpił błąd podczas zapisywania wydarzenia.'

      setSavingError(true)
      setErrorMessage(errorMessage)
      setLoading(false)

      throw new Error(errorMessage)
    }

    const updatedAttachmnets = updatedEventImagesUrls.map((url) => ({
      ...emptyAttachmentObj,
      imgURL: url,
    }))

    setAttachmentImages(updatedAttachmnets)
    setSaveButtonDisabled(true)
    setSavingSuccess(true)
    setSuccessMessage('Pomyślnie zapisano zmiany!')
    setIsAfterSave(true)
    setLoading(false)
  }

  const getInitialEventImages = useCallback(() => {
    if (event) {
      const eventImages = event.images.map((imageSrc, index) => ({
        imgURL: imageSrc,
        imgBase64: '',
        imgFile: null,
        fileName: '',
        isNewImg: false,
        // editedImgSrc: '',
        // selected: false,
        index,
      }))

      initialAttachmentsRef.current = eventImages

      setAttachmentImages(eventImages)
      setCurrentImg({
        imgURL: event.images[0] || '',
        imgBase64: '',
        imgFile: null,
        fileName: '',
        isNewImg: false,
        index: 0,
      })
    } else {
      initialAttachmentsRef.current = []
    }
  }, [event])

  useEffect(() => {
    getInitialEventImages()
  }, [event, getInitialEventImages])

  useEffect(() => {
    isAttachmentsArrayEqualWithInitial()
  }, [attachmentsImages, isAttachmentsArrayEqualWithInitial])

  return (
    <>
      <StyledModal
        show={visible}
        onHide={() => {
          close('event')
          resetAll()
        }}
        $overlay={editFormVisible}
      >
        <ModalHeader>
          <ModalHeaderIcons>
            <ArrowLeft color='#8b8b8b' size={18} onClick={() => close('event')} />
          </ModalHeaderIcons>
        </ModalHeader>
        <ModalBody>
          <EventInfo
            eventName={event?.eventName}
            address={event?.address}
            status={event?.status}
            type={event?.status}
            description={event?.description}
            team={event?.team}
            valuators={event?.valuators}
            performers={event?.performers}
          />
          <EventImages>
            <MainWrapper
              withBorder={
                !imageEditorVisible &&
                event &&
                attachmentsImages.length > 0 &&
                (currentImg.imgURL || currentImg.imgBase64)
              }
            >
              {imageEditorVisible ? (
                <ImageEditor
                  addImgToForm={handleAddImageToAttachmentsAndForm}
                  // testAPI={handleTestAPI}
                  forwardedImageFile={currentImg.imgFile}
                  forwardedImageFileName={currentImg.fileName}
                  hide={() => {
                    setImageEditorVisible(false)
                    setUploadedImages([])
                  }}
                />
              ) : (
                <>
                  {event && attachmentsImages.length > 0 && (
                    <CurrentImg src={currentImg.imgBase64 || currentImg.imgURL} alt='' />
                  )}
                </>
              )}
              {/* {!!currentImg.img && attachmentsImages.length > 0 && (
                <EditIconWrapper onClick={() => setImageEditorVisible(true)} absolute>
                  <PencilFill color='#ffffff' size={15} />
                </EditIconWrapper>
              )} */}
            </MainWrapper>

            <Attachments
              images={attachmentsImages}
              disabled={imageEditorVisible}
              currentImgIndex={currentImg.index}
              changeCurrentImg={setCurrentImg}
              onImageUpload={handleImageUpload}
              deleteAttachment={handleAttachmentDelete}
              // selectAttachment={handleAttachmentSelectToggle}
            />
          </EventImages>
          <EventButtons>
            <Col sm='auto'>
              <Button
                type='button'
                color='success'
                variant='success'
                disabled={saveButtonDisabled || imageEditorVisible}
                onClick={handleSaveChanges}
              >
                Zapisz zmiany
              </Button>
            </Col>
            <Col sm='auto'>
              <Button
                variant='danger'
                onClick={resetAll}
                disabled={saveButtonDisabled || imageEditorVisible}
              >
                Anuluj zmiany
              </Button>
            </Col>
          </EventButtons>
        </ModalBody>
      </StyledModal>
      <EditCustomerDataModal visible={editFormVisible} close={() => setEditFormVisible(false)} />
      <InfoModal
        visible={loading || savingError || savingSuccess}
        loading={loading}
        error={savingError}
        errorMessage={errorMessage}
        success={savingSuccess}
        successMessage={successMessage}
        close={() => {
          setLoading(false)
          setSavingError(false)
          setSavingSuccess(false)
          setSaveButtonDisabled(true)
        }}
        refetch={refetch}
      />
    </>
  )
}

export default EventModal

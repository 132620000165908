/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useContext} from 'react'
import {AuthContext} from '../../../../app/contexts/AuthContext'

import {toAbsoluteUrl} from '../../../helpers'

import {logout} from '../../../../api_wentklima'

import {
  deleteSavedSessionId,
  deleteSavedUser,
  getSavedUser,
} from '../../../../utils/handleLocalStorage'

const HeaderUserMenu: FC = () => {
  const {user, setIsAuthorized} = useContext(AuthContext)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{user?.user_firstname}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.user_lastname}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            logout()
              .then(() => {
                deleteSavedSessionId()
                deleteSavedUser()
                setIsAuthorized(false)
              })
              .catch((err) => console.log(err))
          }}
          className='menu-link px-5'
        >
          Wyloguj się
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

import {UserModel} from '../app/modules/auth/models/UserModel'

export const saveUser = (user: UserModel) => {
  const userStringified = JSON.stringify(user)
  localStorage.setItem('user', userStringified)
}

export const getSavedUser = (): UserModel | null =>
  JSON.parse(localStorage.getItem('user') || 'null') as UserModel

export const saveSessionId = (sessionId: string) => localStorage.setItem('sessionId', sessionId)

export const getSavedSessionId = (): string | null => localStorage.getItem('sessionId') || null

export const deleteSavedUser = () => localStorage.removeItem('user')

export const deleteSavedSessionId = () => localStorage.removeItem('sessionId')

import React from 'react'
import styled from 'styled-components'

import {Modal} from 'react-bootstrap'

import {BriefcaseFill} from 'react-bootstrap-icons'

import type {Event} from '../../../../types/types'

type Props = Pick<
  Event,
  'eventName' | 'description' | 'address' | 'status' | 'type' | 'team' | 'valuators' | 'performers'
>

const ModalTitle = styled(Modal.Title)`
  margin: 0.5rem 0 1.25rem 0;
  font-size: 1.7rem;
`

const Address = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 0 0 0 1rem;
    font-size: 1.1rem;
    color: #6b6b6b;
  }
`

const PieceOfInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1.1rem;
  color: #6b6b6b;

  b {
    font-size: 1.225rem;
    margin-right: 5px;
  }

  p {
    margin: 0;
  }

  margin-bottom: 12px;
`

const Description = styled(PieceOfInfo)`
  flex-direction: column;
  align-items: flex-start;
`

const EventInfo: React.FC<Props> = ({
  eventName,
  address,
  status,
  type,
  description,
  team,
  valuators,
  performers,
}) => {
  const parsedDescription = description && description.replaceAll('\r\n', '</br>')

  return (
    <div>
      <Address>
        <BriefcaseFill color='#9030ff' size={20} />
        <p>{address}</p>
      </Address>
      <ModalTitle>{eventName}</ModalTitle>
      <PieceOfInfo>
        <b>Status: </b>
        <p>{status}</p>
      </PieceOfInfo>
      <PieceOfInfo>
        <b>Typ: </b>
        <p>{type}</p>
      </PieceOfInfo>
      {parsedDescription && (
        <Description>
          <b>Komentarz:</b>
          <div dangerouslySetInnerHTML={{__html: parsedDescription}} />
        </Description>
      )}
      {team && (
        <PieceOfInfo>
          <b>Ekipa montażowa: </b>
          <p>{team}</p>
        </PieceOfInfo>
      )}
      {valuators && (
        <PieceOfInfo>
          <b>Wycena: </b>
          <p>{valuators}</p>
        </PieceOfInfo>
      )}
      {performers && (
        <PieceOfInfo>
          <b>Wykonawcy: </b>
          <p>{performers}</p>
        </PieceOfInfo>
      )}
    </div>
  )
}

export default EventInfo

import React, {createContext, useEffect, useState} from 'react'
import {UserModel} from '../modules/auth/models/UserModel'

import {getSavedSessionId, getSavedUser} from '../../utils/handleLocalStorage'

import {checkActiveSession} from '../../api_wentklima'

type AuthProviderProps = {
  children: React.ReactNode
}

type AuthContextType = {
  user: UserModel | null
  isAuthorized: boolean
  isLoading: boolean
  setIsAuthorized: (value: boolean) => void
  setUser: (user: UserModel) => void
}

const defaultContextValues = {
  user: null,
  isAuthorized: false,
  isLoading: true,
  setIsAuthorized: (value: boolean) => null,
  setUser: (user: UserModel) => null,
}

export const AuthContext = createContext<AuthContextType>(defaultContextValues)

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const [user, setUser] = useState<UserModel | null>(null)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const reset = () => {
    setUser(null)
    setIsAuthorized(false)
    setIsLoading(false)
  }

  const setAuthorizedState = (value: boolean) => {
    setIsAuthorized(value)
  }

  useEffect(() => {
    const savedSessionId = getSavedSessionId()

    if (!savedSessionId) {
      setIsLoading(false)
      return
    }

    checkActiveSession()
      .then((response) => {
        if (!response?.data?.result) {
          reset()
          return
        }

        const savedUser = getSavedUser()
        setUser(savedUser)
        setIsAuthorized(true)
        setIsLoading(false)
      })
      .catch(() => reset())
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthorized,
        isLoading,
        setIsAuthorized: setAuthorizedState,
        setUser: (user: UserModel) => setUser(user),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

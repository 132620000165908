import React from 'react'
import {Brush} from 'react-bootstrap-icons'
import styled, {css} from 'styled-components'

type Props = {
  active: boolean
  mode: 'FREE_DRAWING' | 'LINE_DRAWING'
  icon: 'draw' | 'line'
  label: 'Rysowanie' | 'Linia prosta'
  setMode: (mode: 'FREE_DRAWING' | 'LINE_DRAWING') => void
}

const Line = styled.div<{active: boolean}>`
  width: 28px;
  height: 1px;
  margin-top: 10px !important;
  margin-bottom: 9px !important;
  background-color: ${({active}) => (active ? '#000000' : '#888888')};
`

const Wrapper = styled.div<{active: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #888888;
  cursor: pointer;

  &:first-child {
    margin-right: 1rem;
  }

  &:hover {
    color: #000000;

    & > ${Line} {
      background-color: #000000;
    }
  }

  ${({active}) =>
    active &&
    css`
      color: #000000;
    `}
`

const Label = styled.p`
  margin: 0;
  font-size: 1rem;
`

const CustomDrawModePicker: React.FC<Props> = ({active, mode, icon, label, setMode}) => {
  return (
    <Wrapper active={active} onClick={() => setMode(mode)}>
      {icon === 'draw' ? <Brush size={20} /> : <Line active={active} />}
      <Label> {label} </Label>
    </Wrapper>
  )
}

export default CustomDrawModePicker

import {ServiceType, Event} from '../types/types'

export const parseServicesToEvents = (services: ServiceType[]): Event[] => {
  const parsedEvents: Event[] = services.map((service) => {
    const valuatorsNames = service.valuators.map(
      (valuator) => `${valuator.user_firstname} ${valuator.user_lastname}`
    )
    const performersNames = service.performers.map(
      (performer) => `${performer.user_firstname} ${performer.user_lastname}`
    )

    const valuators = valuatorsNames.join(', ')
    const performers = performersNames.join(', ')

    return {
      id: String(service.id),
      dateStart: service.date,
      dateEnd: service.date_to,
      eventName: service.title,
      address: `${service.location.street.name} ${service.location.number}, ${service.location.code}, ${service.location.city.name} ${service.location.district.name}, ${service.location.type.name}`,
      status: service.status.name,
      type: service.type.name,
      description: service.comment,
      team: service.team.name,
      valuators,
      performers,
      url: service.url,
      images: service.projects.filter((projectURL) => projectURL !== ''),
    }
  })

  return parsedEvents
}

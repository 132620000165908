import styled, {css, keyframes} from 'styled-components'

const spinAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }

`

const Loader = styled.div<{blue?: boolean; big?: boolean}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 20px;
  height: 20px;
  &:after {
    content: ' ';
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${spinAnimation} 1.2s linear infinite;
  }

  ${({blue}) =>
    blue &&
    css`
      &:after {
        border-color: #009ef7 transparent #009ef7 transparent !important;
      }
    `}

  ${({big}) =>
    big &&
    css`
      width: 42px;
      height: 42px;
      &:after {
        width: 42px;
        height: 42px;
        border: 3px solid #fff;
      }
    `}
`

export default Loader

import axios from 'axios'
import {getSavedSessionId} from '../utils/handleLocalStorage'

// const WENTKLIMA_API_URL =
//   'https://cors-anywhere.indigital.guru/https://crm.klimatyzacjawentklima.pl/api'
const WENTKLIMA_API_URL = 'https://crm.klimatyzacjawentklima.pl/api'
const IMAGES_SERVICE_URL = 'https://pliki-klimatyzacjawentklima.pl/'

const API_METHODS = {
  login: 'user_login',
  logout: 'user_logout',
  checkSession: 'check_session',
  userServices: 'user_services',
  serviceLoad: 'service_load',
  updateService: 'service_update',
}

export const login = async (usernameBase64Encoded: string, passwordBase64Encoded: string) => {
  return await axios.get(
    `${WENTKLIMA_API_URL}?method=${API_METHODS.login}&username=${usernameBase64Encoded}&password=${passwordBase64Encoded}, `
  )
}

export const logout = async () => {
  const sessionId = getSavedSessionId()
  return await axios.get(
    `${WENTKLIMA_API_URL}?method=${API_METHODS.logout}&session_id=${sessionId}`
  )
}

export const checkActiveSession = async () => {
  const sessionId = getSavedSessionId()
  return await axios.get(`${WENTKLIMA_API_URL}?method=check_session&session_id=${sessionId}`)
}

export const getUserServices = async (date: {month: number; year: number}) => {
  // 'date' argument must be in YYYY-MM format
  const sessionId = getSavedSessionId()
  return await axios.get(
    `${WENTKLIMA_API_URL}?method=${API_METHODS.userServices}&session_id=${sessionId}&month=${date.year}-${date.month}`
  )
}

export const getServiceDetails = async (serviceId: number) => {
  // 'date' argument must be in YYYY-MM format
  const sessionId = getSavedSessionId()
  return await axios.get(
    `${WENTKLIMA_API_URL}?method=${API_METHODS.serviceLoad}&session_id=${sessionId}&service_id=${serviceId}`
  )
}

export const saveServiceImages = async (urls: string[], serviceId: number) => {
  const sessionId = getSavedSessionId()

  const URL = `${WENTKLIMA_API_URL}?method=${
    API_METHODS.updateService
  }&session_id=${sessionId}&service_id=${serviceId}&projects=${[urls]}`

  try {
    const response = await axios.post(URL, [urls])
    return response
  } catch {
    return {
      data: {
        error: true,
      },
    }
  }
}

export const saveUploadedImages = async (imageFiles: File[]) => {
  if (!imageFiles.length) {
    return Promise.resolve({
      data: {
        code: 200,
        uploads: [],
      },
    })
  }

  const formData = new FormData()

  imageFiles.forEach((file, index) => {
    formData.append(`file${index + 1}`, file, `file-${index}.png`)
  })

  formData.append('token', 'WEFNR3423TRHGFDFBGGB')

  return await axios.post(IMAGES_SERVICE_URL, formData)
}

import React from 'react'
import styled, {css} from 'styled-components'
import Attachment from './Attachment'

import {AttachmentType} from '../../../../types/types'

import {v4 as uuid} from 'uuid'

type Props = {
  images: AttachmentType[]
  disabled: boolean
  currentImgIndex: number
  onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeCurrentImg: (img: AttachmentType) => void
  deleteAttachment: (index: number) => void
  // selectAttachment: (index: number, selected: boolean) => void
}

const Heading = styled.h6`
  font-size: 1.25rem;
  margin-top: 1.35rem;
`

const Thumbnails = styled.div<{grayedOut: boolean}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${({grayedOut}) =>
    grayedOut &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`

const ThumbnailInputWrapper = styled.div``

const ThumbnailInput = styled.input`
  display: none;
`

const ThumbnailInputLabel = styled.label`
  position: relative;
  width: 65px;
  height: 65px;
  box-sizing: border-box;
  border: 2px dashed #bbbbbb;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  &:after {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    color: #bbbbbb;
  }
`

const Attachments: React.FC<Props> = ({
  images,
  disabled,
  currentImgIndex,
  onImageUpload,
  changeCurrentImg,
  deleteAttachment,
  // selectAttachment,
}) => {
  return (
    <>
      <Heading>Załączniki</Heading>
      <Thumbnails grayedOut={disabled}>
        {images.map(
          ({
            imgURL,
            imgBase64,
            isNewImg,
            // editedImgSrc,
            // selected,
            index,
          }) => (
            <Attachment
              key={uuid()}
              index={index}
              highlighted={!disabled && index === currentImgIndex}
              isNewImg={isNewImg}
              imgURL={imgURL}
              imgBase64={imgBase64}
              // selected={selected}
              disabled={disabled}
              changeCurrentImg={changeCurrentImg}
              deleteAttachment={deleteAttachment}
              // selectAttachment={selectAttachment}
              // editedImgSrc={editedImgSrc}
            />
          )
        )}
        {!disabled && (
          <ThumbnailInputWrapper>
            <ThumbnailInputLabel htmlFor='image' />
            <ThumbnailInput type='file' id='image' onChange={(e) => onImageUpload(e)} />
          </ThumbnailInputWrapper>
        )}
      </Thumbnails>
    </>
  )
}

export default Attachments

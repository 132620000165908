import React from 'react'
import styled from 'styled-components'
import {Formik, Form as FormikForm, Field} from 'formik'
import {Button, Form} from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import FormInput from '../../../helpers/components/Input'

import {EDIT_CUSTOMER_DATA_SCHEMA} from '../../../helpers/FormSchemas'

const Container = styled.div`
  height: 500px;
  overflow-y: scroll;
  padding: 0 1rem;

  * {
    scrollbar-width: auto;
    scrollbar-color: #ccc9c9;
  }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc9c9;
    border-radius: 3px;
    border: 2px solid transparent;
  }
`

const FormGroupLabel = styled(Form.Label)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
`

const FormGroup = styled(Form.Group)`
  margin-bottom: 1rem;
`

const SubmitButton = styled(Button)`
  min-width: 165px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const NewEventForm = () => {
  return (
    <Container>
      <Formik
        validationSchema={EDIT_CUSTOMER_DATA_SCHEMA}
        initialValues={{
          customerName: '',
          customerSurname: '',
          customerAddress: '',
          customerEmail: '',
          customerPhone: '',
        }}
        onSubmit={(values, {setSubmitting}) => {
          ;(async () => {
            try {
              console.log(values)
              setSubmitting(true)

              setTimeout(() => {
                alert('Zmiany zostaną zapisane')
                setSubmitting(false)
              }, 3000)
            } catch (err) {
              console.log(err)
            }
          })()
        }}
      >
        {({isSubmitting, dirty, isValid}) => (
          <FormikForm>
            <FormGroupLabel>Edytuj dane klienta</FormGroupLabel>
            <FormGroup>
              <Field name='customerName' component={FormInput} label='Imię' placeholder='Imię' />
            </FormGroup>
            <FormGroup>
              <Field
                name='customerSurname'
                component={FormInput}
                label='Nazwisko'
                placeholder='Nazwisko'
              />
            </FormGroup>
            <FormGroup>
              <Field
                name='customerAddress'
                component={FormInput}
                label='Adres'
                placeholder='ul. Cypryjska 8, 03-732 Warszawa'
              />
            </FormGroup>
            <FormGroup>
              <Field name='customerEmail' component={FormInput} label='Email' placeholder='Email' />
            </FormGroup>
            <FormGroup>
              <Field
                name='customerPhone'
                component={FormInput}
                label='Telefon'
                placeholder='Telefon'
              />
            </FormGroup>
            <ButtonWrapper>
              <SubmitButton variant='primary' type='submit' disabled={!(isValid && dirty)}>
                {isSubmitting ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Zapisz'
                )}
              </SubmitButton>
            </ButtonWrapper>
          </FormikForm>
        )}
      </Formik>
    </Container>
  )
}

export default NewEventForm

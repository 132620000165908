import React from 'react'
import styled, {css} from 'styled-components'

type Props = {
  visible: boolean
  onHide: () => void
  onColorPick: (color: string, colorLabel: string) => void
}

const colorsWithLabels = [
  {label: 'Instalacja chłodnicza', value: '#ff0000'},
  {label: 'Instalacja chłodnicza z korytkiem', value: '#ee9f00'},
  {label: 'Instalacja wody', value: '#02a9ff'},
  {label: 'Instalacja elektryczna', value: '#e2e50e'},
  {label: 'Instalacja komunikacji', value: '#00ff00'},
]

const ColorItemsList = styled.ul``

const Wrapper = styled.div<{visible: boolean}>`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: #fff;
  box-shadow: 0 3px 22px 6px rgb(0 0 0 / 15%);
  z-index: 1000;
  visibility: hidden;

  ${({visible}) =>
    visible &&
    css`
      visibility: visible;
    `}

  // Overriding custom styles
  & > ${ColorItemsList} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.15rem;
  }
`

const ColorItem = styled.li`
  display: flex !important ;
  align-items: center !important ;
  width: 100% !important;
  padding: 0.5rem !important;
  &:hover {
    background-color: #f8f9fb;
    color: #5097ca;
  }
`

const ColorPreviewCircle = styled.div<{color: string}>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({color}) => color};
  margin-right: 1rem !important;
`

const ColorLabel = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
`

const CustomColorPalette: React.FC<Props> = ({visible, onHide, onColorPick}) => {
  return (
    <Wrapper visible={visible}>
      <ColorItemsList>
        {colorsWithLabels.map((color) => (
          <ColorItem
            key={color.label}
            onClick={() => {
              onColorPick(color.value, color.label)
              onHide()
            }}
          >
            <ColorPreviewCircle color={color.value} />
            <ColorLabel> {color.label} </ColorLabel>
          </ColorItem>
        ))}
      </ColorItemsList>
    </Wrapper>
  )
}

export default CustomColorPalette

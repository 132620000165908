import React from 'react'
import styled, {css} from 'styled-components'

import {Button, Col, Modal, Row} from 'react-bootstrap'
import Loader from '../Loader'

type Props = {
  visible: boolean
  loading: boolean
  error: boolean
  errorMessage: string
  success: boolean
  successMessage: string
  close: () => void
  refetch: () => void
}

const ModalWrapper = styled(Modal)`
  z-index: 9999;
  background: #00000057;

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div<{center: boolean; withLine: boolean}>`
  position: relative;
  padding-bottom: 1.75rem;

  &:after {
    display: none;
  }

  ${({center}) =>
    center &&
    css`
      text-align: center;
    `}

  ${({withLine}) =>
    withLine &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: -50px;
        width: 120%;
        height: 1px;
        background-color: #e4e6ef;
      }
    `}
`

const HeaderText = styled.p<{red?: boolean; green?: boolean}>`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0;

  ${({red}) =>
    red &&
    css`
      color: red;
    `}

  ${({green}) =>
    green &&
    css`
      color: green;
    `}
`

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1.75rem;

  & > button {
    :first-child {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -50px;
    width: 120%;
    height: 1px;
    background-color: #e4e6ef;
  }
`

const TextWrapper = styled.div`
  & > p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
`

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
`

const InfoModal: React.FC<Props> = ({
  visible,
  loading,
  error,
  errorMessage,
  success,
  successMessage,
  close,
  refetch,
}) => {
  return (
    <ModalWrapper show={visible || loading}>
      <Body>
        <Header center={loading} withLine={!loading}>
          {loading && <HeaderText>Trwa zapisywanie zmian. Proszę czekać...</HeaderText>}
          {error && <HeaderText red>Błąd!</HeaderText>}
          {success && <HeaderText green>Sukces!</HeaderText>}
        </Header>

        {error && (
          <TextWrapper>
            <p>{errorMessage}</p>
          </TextWrapper>
        )}
        {success && (
          <TextWrapper>
            <p>{successMessage}</p>
          </TextWrapper>
        )}

        {!loading && (
          <ModalFooter>
            <Button
              variant='primary'
              onClick={() => {
                close()
                refetch()
              }}
            >
              OK
            </Button>
          </ModalFooter>
        )}

        {/* Loader */}
        {loading && (
          <LoaderWrapper>
            <Loader blue big />
          </LoaderWrapper>
        )}
        {/* Loader */}
      </Body>
    </ModalWrapper>
  )
}

export default InfoModal

import {FC, useContext} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {AuthPage} from '../modules/auth'
import {App} from '../App'
import {AuthContext} from '../contexts/AuthContext'
import {DashboardPage} from '../pages/DashboardPage'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

const AppRoutes: FC = () => {
  const {isAuthorized} = useContext(AuthContext)

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {isAuthorized ? (
            <Route element={<MasterLayout />}>
              <Route path='dashboard' element={<DashboardPage />} />
              <Route path='*' element={<Navigate to='/dashboard' />} />
            </Route>
          ) : (
            <>
              <Route path='/auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState, useMemo, useContext, useCallback} from 'react'
import FullCalendar, {EventClickArg} from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import plLocale from '@fullcalendar/core/locales/pl'

// import NewEventModal from '../../_metronic/layout/components/modals/NewEventModal'
import EventModal from '../../_metronic/layout/components/modals/EventModal'
import EventsErrorModal from '../../_metronic/layout/components/modals/EventsErrorModal'
import CalendarLoader from '../../_metronic/layout/components/CalendarLoader'

import {AuthContext} from '../contexts/AuthContext'
import {deleteSavedSessionId, deleteSavedUser} from '../../utils/handleLocalStorage'

import {parseServicesToEvents} from '../../utils/parseEvents'
import {checkActiveSession, getUserServices} from '../../api_wentklima'
import type {Event, ServiceType} from '../../types/types'

const MINUTE_MS = 60000

const DashboardPage: FC = () => {
  const [modalsVisible, setModalsVisible] = useState({
    newEvent: false,
    event: false,
  })

  const [events, setEvents] = useState<Event[]>([])
  // const [startDate, setStartDate] = useState('')
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null)
  const [currentDateParams, setCurrentDateParams] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const [eventsLoading, setEventsLoading] = useState(true)
  const [eventsLoadingError, setEventsLoadingError] = useState(false)

  const {setIsAuthorized} = useContext(AuthContext)

  const handleClose = (modalType: string) =>
    setModalsVisible({
      ...modalsVisible,
      [modalType]: false,
    })

  const handleShow = (modalType: string) =>
    setModalsVisible({
      ...modalsVisible,
      [modalType]: true,
    })

  const handleEventClick = (event: EventClickArg) => {
    const foundEvent = events.find((e) => e.id === event.event._def.publicId)

    if (foundEvent) {
      setSelectedEvent(foundEvent)
      handleShow('event')
    }
  }

  const calendarEvents = useMemo(
    () =>
      events.map((e, index) => ({
        // @ts-ignore
        title: e?.eventName,
        // @ts-ignore
        start: e.dateStart,
        end: e.dateEnd,
        // @ts-ignore
        id: e?.id,
      })),
    [events]
  )

  const fetchEvents = useCallback(() => {
    getUserServices({
      month: currentDateParams.month,
      year: currentDateParams.year,
    })
      .then((response) => {
        const services: ServiceType[] = response?.data?.result

        const events = parseServicesToEvents(services)

        setEvents(events)
        setEventsLoading(false)
        setEventsLoadingError(false)
      })
      .catch(() => {
        setEventsLoading(false)
        setEventsLoadingError(true)
      })
  }, [currentDateParams.month, currentDateParams.year])

  useEffect(() => {
    fetchEvents()
  }, [currentDateParams.month, currentDateParams.year, fetchEvents])

  useEffect(() => {
    const prevButton = document.querySelector('.fc-prev-button')
    const nextButton = document.querySelector('.fc-next-button')

    prevButton?.addEventListener('click', () => setEventsLoading(true))
    nextButton?.addEventListener('click', () => setEventsLoading(true))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      checkActiveSession().then((response) => {
        if (!response?.data?.result) {
          deleteSavedSessionId()
          deleteSavedUser()
          setIsAuthorized(false)
        }
      })
    }, MINUTE_MS)

    return () => clearInterval(interval)
  }, [setIsAuthorized])

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        selectable={true}
        // select={({startStr}) => {
        //   setStartDate(startStr)
        //   handleShow('newEvent')
        // }}
        initialView='dayGridMonth'
        locale={plLocale}
        selectLongPressDelay={0}
        // @ts-ignore
        events={calendarEvents}
        displayEventTime={false}
        eventClick={(eventObj) => handleEventClick(eventObj)}
        datesSet={(dateInfo) => {
          //@ts-ignore
          const currentDate: Date = dateInfo.view.getCurrentData().currentDate
          const currentMonth = currentDate.getMonth() + 1
          const currentYear = currentDate.getFullYear()

          setCurrentDateParams({
            month: currentMonth,
            year: currentYear,
          })
        }}
      />

      <CalendarLoader visible={eventsLoading} />
      {/* <NewEventModal visible={modalsVisible.newEvent} close={handleClose} startDate={startDate} /> */}
      <EventModal
        visible={modalsVisible.event}
        close={handleClose}
        refetch={fetchEvents}
        event={selectedEvent}
      />
      <EventsErrorModal
        visible={!eventsLoading && eventsLoadingError}
        close={() => {
          setEventsLoadingError(false)
          setEventsLoading(false)
        }}
      />
    </>
  )
}

export {DashboardPage}

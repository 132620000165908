import {useContext} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {login} from '../../../../api_wentklima'
import {saveSessionId, saveUser} from '../../../../utils/handleLocalStorage'
import {AuthContext} from '../../../contexts/AuthContext'
import {UserModel} from '../models/UserModel'

const loginSchema = Yup.object().shape({
  login: Yup.string()
    .min(3, 'Login musi zawierać minimum 3 znaki')
    .max(50, 'Hasło może maksymalnie zawierać 50 znaków')
    .required('Proszę podać login'),
  password: Yup.string()
    .min(3, 'Hasło musi zawierać minimum 3 znaki')
    .max(50, 'Hasło może maksymalnie zawierać 50 znaków')
    .required('Proszę podać hasło'),
})

const initialValues = {
  login: '',
  password: '',
}

export function Login() {
  const {setIsAuthorized, setUser} = useContext(AuthContext)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)

      const encodedEmail = window.btoa(values.login)
      const encodedPassword = window.btoa(values.password)

      login(encodedEmail, encodedPassword)
        .then((response) => {
          if (!response.data.result) {
            setSubmitting(false)
            setStatus('Nieprawidłowy login i / lub hasło')
            return
          }

          const sessionId = response?.data?.result?.session
          saveSessionId(sessionId)
          saveUser(response?.data?.result)
          setUser(response?.data?.result as UserModel)
          setSubmitting(false)
          setIsAuthorized(true)
        })
        .catch((err) => {
          console.log(err)

          setSubmitting(false)
          setStatus('Błąd podczas logowania')
        })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Aplikacja wspierająca obsługę klientów</h1>
        <div className='text-gray-400 fw-bold fs-4'>Zaloguj się do panelu </div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Aby zalogować się do aplikacji, wpisz swój <strong>login i hasło.</strong>
          </div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Login</label>
        <input
          placeholder='Login'
          {...formik.getFieldProps('login')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.login && formik.errors.login},
            {
              'is-valid': formik.touched.login && !formik.errors.login,
            }
          )}
          name='login'
          autoComplete='off'
        />
        {formik.touched.login && formik.errors.login && (
          <div className='input-message-container'>
            <span className='invalid' role='alert'>
              {formik.errors.login}
            </span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Hasło</label>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='input-message-container'>
            <div className='invalid'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && <span className='indicator-label'>Kontynuuj</span>}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Proszę czekać...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

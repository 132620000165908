import * as Yup from 'yup'

export const NEW_EVENT_SCHEMA = Yup.object().shape({
  eventName: Yup.string().required('Proszę podać nazwę wydarzenia'),
  dateStart: Yup.date().required('Podaj dzień rozpoczęcia'),
  dateEnd: Yup.date()
    .min(Yup.ref('dateStart'), 'Nieprawidłowa data zakończenia!')
    .required('Podaj dzień zakończenia'),
  timeStart: Yup.string(),
  timeEnd: Yup.string(),
  customerName: Yup.string().required('Proszę podać imię'),
  customerSurname: Yup.string().required('Proszę podać nazwisko'),
  customerStreet: Yup.string().required('Proszę podać ulicę'),
  customerFlatNumber: Yup.string().required('Proszę podać numer'),
  customerPostalCode: Yup.string().required('Proszę podać kod pocztowy'),
  customerCity: Yup.string().required('Proszę podać miejscowość'),
  customerEmail: Yup.string()
    .email(`Adres email jest nieprawidłowy`)
    .required(`Proszę podać adres e-mail`),
  customerPhone: Yup.string()
    .min(9, `Numer telefonu jest nieprawidłowy`)
    .matches(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
      `Numer telefonu jest nieprawidłowy`
    )
    .required(`Proszę podać numer telefonu`),
  installationName: Yup.string().required('Proszę podać imię'),
  installationSurname: Yup.string().required('Proszę podać nazwisko'),
  installationEmail: Yup.string()
    .email(`Adres email jest nieprawidłowy`)
    .required(`Proszę podać adres e-mail`),
  installationPhone: Yup.string()
    .min(9, `Numer telefonu jest nieprawidłowy`)
    .matches(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
      `Numer telefonu jest nieprawidłowy`
    )
    .required(`Proszę podać numer telefonu`),
  installationStreet: Yup.string().required('Proszę podać ulicę'),
  installationFlatNumber: Yup.string().required('Proszę podać numer'),
  installationPostalCode: Yup.string().required('Proszę podać kod pocztowy'),
  installationCity: Yup.string().required('Proszę podać miejscowość'),
  installationTeam: Yup.string().required('Proszę wybrać ekipę'),
})

export const NEW_EVENT_SCHEMA_SAME_DATA = Yup.object().shape({
  eventName: Yup.string().required('Proszę podać nazwę wydarzenia'),
  dateStart: Yup.date().required('Podaj dzień rozpoczęcia'),
  dateEnd: Yup.date()
    .min(Yup.ref('dateStart'), 'Nieprawidłowa data zakończenia!')
    .required('Podaj dzień zakończenia'),
  timeStart: Yup.string(),
  timeEnd: Yup.string(),
  customerName: Yup.string().required('Proszę podać imię'),
  customerSurname: Yup.string().required('Proszę podać nazwisko'),
  customerStreet: Yup.string().required('Proszę podać ulicę'),
  customerFlatNumber: Yup.string().required('Proszę podać numer'),
  customerPostalCode: Yup.string().required('Proszę podać kod pocztowy'),
  customerCity: Yup.string().required('Proszę podać miejscowość'),
  customerEmail: Yup.string()
    .email(`Adres email jest nieprawidłowy`)
    .required(`Proszę podać adres e-mail`),
  customerPhone: Yup.string()
    .min(9, `Numer telefonu jest nieprawidłowy`)
    .matches(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
      `Numer telefonu jest nieprawidłowy`
    )
    .required(`Proszę podać numer telefonu`),
  installationTeam: Yup.string().required('Proszę wybrać ekipę'),
})

export const EDIT_CUSTOMER_DATA_SCHEMA = Yup.object().shape({
  customerName: Yup.string().required('Proszę podać imię'),
  customerSurname: Yup.string().required('Proszę podać nazwisko'),
  customerAddress: Yup.string().required('Proszę podać adres'),
  customerEmail: Yup.string()
    .email(`Adres email jest nieprawidłowy`)
    .required(`Proszę podać adres e-mail`),
  customerPhone: Yup.string()
    .min(9, `Numer telefonu jest nieprawidłowy`)
    .matches(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
      `Numer telefonu jest nieprawidłowy`
    )
    .required(`Proszę podać numer telefonu`),
})

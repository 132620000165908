export const locale_pl_PL = {
  Apply: 'Zastosuj',
  Cancel: 'Anuluj',
  Color: 'Kolor',
  Crop: 'Przytnij',
  Delete: 'Usuń',
  DeleteAll: 'Usuń wszystkie zmiany',
  Download: 'Pobierz',
  Draw: 'Rysuj',
  Free: 'Rysowanie',
  Load: 'Dodaj zdjęcie',
  Range: 'Rozmiar',
  Redo: 'Ponów',
  Reset: 'Resetuj zmiany',
  Stroke: 'Stroke',
  Undo: 'Cofnij',
  Straight: 'Linia prosta',
}

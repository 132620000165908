// @ts-nocheck

import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import BaseImageEditor from '@toast-ui/react-image-editor'

// package to save files
import saveAs from 'file-saver'

// tui-image-editor styles
import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'

// temporary fan images
import samsungIq70 from './assets/samsung_iq70.png'
import samsungIq12 from './assets/samsung_iq12.png'
import samsung230 from './assets/samsung_230.png'

// bootstrap components
import {Row, Dropdown, Button, Col} from 'react-bootstrap'

// polish translation for tui-image-editor
import {locale_pl_PL} from './locale.ts'

// white theme for tui-image-editor
import {whiteTheme} from './whiteTheme.ts'

// custom pickers
import CustomColorPalette from './CustomColorPalette'
import CustomDrawModePicker from './CustomDrawModePicker'
import CustomBrushSizeSlider from './CustomBrushSizeSlider'

import {removeAllChildNodes} from '../../../../utils/removeAllChilNodes'

import dataUrlToBlob from 'dataurl-to-blob'
import b64ToBlob from 'b64-to-blob'

// FUNCTION TO CONVERT DATA-URL to BLOB
// import {dataURItoBlob} from './utils.ts'

type Props = {
  addImgToForm: (image: any) => void
  forwardedImageFile?: File
  forwardedImageFileName?: string
  hide?: () => void
  // testAPI?: (image: any) => void
  setActive?: Dispatch<SetStateAction<boolean>>
}

type DrawingMode = 'FREE_DRAWING' | 'LINE_DRAWING'

const Wrapper = styled.div`
  margin-top: 3rem;

  /* bootstrap dropdown menu same width as dropdown buttton */
  .dropdown-menu {
    width: 100%;
  }

  .tui-image-editor-container {
    border: 1px solid #e4e6ef;
    border-radius: 0.475rem;
  }

  .tui-image-editor-header-buttons {
    display: none !important;
  }

  & > div > ul {
    // ul is a top menu with buttons inside ImageEditor
    display: flex !important;
    left: 10px !important;
    transform: none !important;
    width: 200px !important;

    & > .tie-btn-zoomIn,
    & > .tie-btn-zoomOut,
    & > .tie-btn-history,
    & > .tie-btn-reset,
    /* & > .tie-btn-delete, */
    & > .tie-btn-hand,
    & > :nth-child(4),
    & > :nth-child(9) {
      display: none !important;
    }
  }

  .tui-image-editor-header {
    .tui-image-editor-header-logo {
      display: none !important;
    }
  }

  .tui-image-editor-menu {
    background-color: #ffffff;
  }
`
const ControlsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50px;
`

const StyledFileInputButton = styled(Button)`
  position: relative;
  cursor: pointer;
`

const StyledFileInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer !important;
  opacity: 0;

  #file-upload-button {
    cursor: pointer !important;
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`

const ImageEditor: FC<Props> = ({
  addImgToForm,
  forwardedImageFile,
  forwardedImageFileName,
  hide,
  setActive,
}) => {
  const editorRef = useRef(null)

  const colorPickerBtnRef = useRef(null)
  const colorPickerPreviewRef = useRef(null)
  const colorPickerLabelRef = useRef(null)
  const drawingStyleControlsWrapperRef = useRef(null)
  const brushSizeSliderWrapperRef = useRef(null)

  const [editorVisible, setEditorVisible] = useState(false)
  const [colorPaletteVisible, setColorPaletteVisible] = useState(false)
  const [currentColor, setCurrentColor] = useState('#02a9ff')
  const [currentDrawingMode, setCurrentDrawingMode] = useState<DrawingMode>('FREE_DRAWING')
  const [currentBrushSize, setCurrentBrushSize] = useState(10)

  // function to add image
  const handleLoadImage = useCallback(
    (e) => {
      const editorInstance = editorRef.current.getInstance()

      editorRef.current.getRootElement().style.display = 'block'

      let file = forwardedImageFile

      setTimeout(() => {
        editorInstance.loadImageFromFile(file).then(function () {
          setEditorVisible(true)
        })
      }, 100)
    },
    [forwardedImageFile]
  )

  // function to add fan to image
  const handleAddFan = (fanModel) => {
    const editorInstance = editorRef.current.getInstance()

    let fanImage = null

    switch (fanModel) {
      case 'samsung_iq70':
        fanImage = samsungIq70
        break
      case 'samsung_iq12':
        fanImage = samsungIq12
        break
      case 'samsung_230':
        fanImage = samsung230
        break
      default:
        fanImage = samsungIq70
    }

    editorInstance.addImageObject(fanImage)

    if (editorInstance.getDrawingMode() === 'FREE_DRAWING') {
      document.querySelector('.tie-btn-draw')?.click()
    }

    editorInstance.stopDrawingMode()
  }

  // function to save image
  const handleSaveImage = () => {
    const editorInstance = editorRef.current.getInstance()

    saveAs(editorInstance.toDataURL())
  }

  // function to reset editor
  const handleResetAll = () => {
    document.querySelector('.tie-btn-reset')?.click()

    setEditorVisible(false)
    if (hide) {
      hide()
    }
    editorRef.current.getRootElement().style.display = 'none'
    document.querySelector('input[type=file]').value = ''
  }

  const handleColorPick = (color: string, colorLabel: string) => {
    const editorInstance = editorRef.current.getInstance()
    editorInstance.stopDrawingMode()
    editorInstance.startDrawingMode('FREE_DRAWING', {
      width: currentBrushSize,
      color: color,
    })

    setCurrentColor(color)

    colorPickerPreviewRef.current.style.backgroundColor = color
    colorPickerLabelRef.current.innerHTML = colorLabel
  }

  const handleColorPaletteHide = () => setColorPaletteVisible(false)

  const handleColorPaletteToggle = useCallback(() => {
    setColorPaletteVisible((prevValue) => !prevValue)
  }, [])

  const handleDrawModeChange = useCallback(
    (mode: DrawingMode) => {
      const editorInstance = editorRef.current.getInstance()
      setCurrentDrawingMode(mode)

      editorInstance.stopDrawingMode()
      editorInstance.startDrawingMode(mode, {
        width: currentBrushSize,
        color: currentColor,
      })
    },
    [currentColor, currentBrushSize]
  )

  const handleChangeBrushSize = useCallback(
    (size: string) => {
      const editorInstance = editorRef.current.getInstance()
      setCurrentBrushSize(size)

      editorInstance.stopDrawingMode()
      editorInstance.startDrawingMode(currentDrawingMode, {
        width: size,
        color: currentColor,
      })
    },
    [currentDrawingMode, currentColor]
  )

  useEffect(() => {
    // Hide image editor initially
    editorRef.current.getRootElement().style.display = 'none'

    //Delete default color palette
    document.querySelector('.color-picker-control')?.remove()

    // Get color picker button element to inject our custom color palette using React Portal and handle toggling
    colorPickerBtnRef.current = document.querySelector('.tie-draw-color.tui-image-editor-button')
    colorPickerBtnRef.current.addEventListener('click', handleColorPaletteToggle)

    // Get color picker preview element (circle) to control its' value
    colorPickerPreviewRef.current = document.querySelector('.color-picker-value')

    // Get color picker label element to control its' value and set default value
    colorPickerLabelRef.current = colorPickerBtnRef.current.lastElementChild
    colorPickerLabelRef.current.innerHTML = 'Instalacja wody'

    // Get drawing mode controls wrapper, delete its children and add custom controls using React Portal
    const drawStyleControlsWrapper = document.querySelector('.tie-draw-line-select-button')
    removeAllChildNodes(drawStyleControlsWrapper)
    drawingStyleControlsWrapperRef.current = drawStyleControlsWrapper

    // Get brush size range slider wrapper, delete its children and add custom slider using React Portal
    const brushSizeRangeEditorEl = document.querySelector(
      '.tui-image-editor-newline.tui-image-editor-range-wrap'
    )
    removeAllChildNodes(brushSizeRangeEditorEl)
    brushSizeSliderWrapperRef.current = brushSizeRangeEditorEl
    brushSizeSliderWrapperRef.current.style.marginTop = '1.35rem'
  }, [handleColorPaletteToggle])

  useEffect(() => {
    if (forwardedImageFile && typeof forwardedImageFile === 'string') {
      editorRef.current.getRootElement().style.display = 'block'
      setEditorVisible(true)
      return
    } else if (forwardedImageFile) {
      handleLoadImage(forwardedImageFile)
    }
  }, [forwardedImageFile, handleLoadImage])

  return (
    <>
      {colorPickerBtnRef.current &&
        ReactDOM.createPortal(
          <CustomColorPalette
            visible={colorPaletteVisible}
            onHide={handleColorPaletteHide}
            onColorPick={handleColorPick}
          />,
          colorPickerBtnRef.current
        )}
      {drawingStyleControlsWrapperRef.current &&
        ReactDOM.createPortal(
          <ControlsWrapper>
            <CustomDrawModePicker
              active={currentDrawingMode === 'FREE_DRAWING'}
              mode='FREE_DRAWING'
              icon='draw'
              label='Rysowanie'
              setMode={handleDrawModeChange}
            />
            <CustomDrawModePicker
              active={currentDrawingMode === 'LINE_DRAWING'}
              mode='LINE_DRAWING'
              icon='line'
              label='Linia prosta'
              setMode={handleDrawModeChange}
            />
          </ControlsWrapper>,
          drawingStyleControlsWrapperRef.current
        )}
      {brushSizeSliderWrapperRef.current &&
        ReactDOM.createPortal(
          <CustomBrushSizeSlider
            color={currentColor}
            min='5'
            max='30'
            onChange={handleChangeBrushSize}
          />,
          brushSizeSliderWrapperRef.current
        )}
      <Wrapper>
        <Row className='mb-3'>
          {/* <>
            <Col sm='auto'>
              <StyledFileInputButton>
                Dodaj zdjęcie
                <StyledFileInput
                  type='file'
                  accept='image/*'
                  onChange={(e) => {
                    handleLoadImage(e)
                    if (setActive) {
                      setActive && setActive(true)
                    }
                  }}
                />
              </StyledFileInputButton>
            </Col>
            <Col sm='auto'>
              <StyledFileInputButton>
                Zrób zdjęcie
                <StyledFileInput
                  type='file'
                  accept='image/*'
                  capture='environment'
                  onChange={(e) => {
                    handleLoadImage(e)
                    if (setActive) {
                      setActive && setActive(true)
                    }
                  }}
                />
              </StyledFileInputButton>
            </Col>
          </> */}

          {editorVisible && (
            <>
              <Col sm='auto'>
                <StyledButton
                  onClick={() => {
                    const editorInstance = editorRef.current.getInstance()
                    const base64PreviewImage = editorInstance.toDataURL()

                    addImgToForm(base64PreviewImage, forwardedImageFileName)
                  }}
                >
                  Dodaj załącznik
                </StyledButton>
              </Col>
              <Col sm='auto'>
                <Dropdown>
                  <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    Wybierz model wentylatora
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleAddFan('samsung_iq70')}>
                      Samsung IQ70
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleAddFan('samsung_iq12')}>
                      Samsung IQ12
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleAddFan('samsung_230')}>
                      Samsung 230
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm='auto'>
                <StyledButton variant='secondary' onClick={() => handleSaveImage()}>
                  Pobierz zdjęcie
                </StyledButton>
              </Col>
              <Col sm='auto'>
                <StyledButton
                  variant='danger'
                  onClick={() => {
                    handleResetAll()
                    if (setActive) {
                      setActive(false)
                    }
                  }}
                >
                  Anuluj
                </StyledButton>
              </Col>
            </>
          )}
        </Row>

        <BaseImageEditor
          ref={editorRef}
          includeUI={{
            loadImage: {
              path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
              name: 'Blank',
            },
            menu: ['draw'],
            initMenu: 'draw',
            uiSize: {
              width: 'auto',
              height: '750px',
            },

            locale: locale_pl_PL,
            theme: whiteTheme,
            menuBarPosition: 'bottom',
          }}
          // cssMaxWidth={document.documentElement.clientWidth}
          // cssMaxHeight={document.documentElement.clientHeight}
          cssMaxWidth={700}
          cssMaxHeight={500}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />
      </Wrapper>
    </>
  )
}

export default ImageEditor

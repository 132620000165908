import React from 'react'
import styled, {keyframes} from 'styled-components'
import {Modal} from 'react-bootstrap'

type Props = {
  visible: boolean
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledModal = styled(Modal)<{overlay: boolean}>`
  display: flex !important;
  align-items: center;
  justify-content: center;

  .modal-content {
    background: transparent;
    background-color: transparent;
    box-shadow: none;
  }
`

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #009ef7;
    border-radius: 50%;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #009ef7 transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const CalendarLoader: React.FC<Props> = ({visible}) => {
  return (
    <StyledModal show={visible}>
      <Spinner className='ASTY'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Spinner>
    </StyledModal>
  )
}

export default CalendarLoader
